.page.account {
    .ant-form {
        .custom-field {
            .code-select .ant-select-selector {
                height: 36px;
                line-height: 36px;

                .ant-select-selection-item {
                    line-height: 36px;
                }
            }
        }
    }
}
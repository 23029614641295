.wireframe {
  min-width: 400px;

  .ant-form-item {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        & > input.ant-input,
        & > .ant-input-password,
        & > .ant-select.ant-select-single .ant-select-selector,
        & > .phone-input .number-input,
        & > .phone-input .code-select .ant-select-selector,
        & > .ant-input-affix-wrapper,
        & > .ant-input-group-wrapper > .ant-input-wrapper > .ant-input,
        & > .keys-box .keys-input {
          height: @height-lg;
          padding: 7px 12px;
          line-height: @height-lg;

          & > .ant-select-selection-item,
          & > .ant-select-selection-placeholder {
            line-height: 20px;
          }

          & > .ant-input {
            padding: 0;
          }
        }

        & > .ant-select.ant-select-multiple .ant-select-selector {
          min-height: @height-lg;
          padding: 1px 12px;

          & > .ant-select-selection-overflow {
            .ant-select-selection-search {
              margin-inline-start: 0;
            }
          }

          & > .ant-input {
            padding: 0;
          }
        }

        & > textarea.ant-input {
          min-height: @height-lg !important;
          padding: 7px 12px;
        }
      }
    }

    &:not(.static-label) {
      margin-bottom: 16px;
    }

    &.static-label {
      margin-bottom: 8px;
    }
  }
}
.mfaWrapper {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  & > form {
    width: 600px;
  }
  & > .wireframe .ant-form-item:not(.static-label) {
    margin-bottom: 0;
  }
  & > .mfaWraper__button {
    height: 67px;
    margin-bottom: auto;
    display: flex;
    align-items: flex-end;
    &.standalone {
      margin-top: 16px;
    }
  }
}
.mfaWrapper--notActive {
  display: flex;
  flex-direction: column;
  & > button {
    width: max-content;
  }
}

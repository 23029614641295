.payout-order-detail {
    .ant-card {
        margin-top: 20px;
    }
    .ant-card-head-title {
        font-weight: bold;
        font-size: 16px;
    }
    .info-title {
        font-size: 14px;
        color: #aaaaaa;
    }
    .info-value {
        margin-top: 4px;
        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;
        & > span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.table-bordered {
    border: 1px solid #797979;
    width: 100%;
    height: 346px;
    overflow-y: auto;
}

.table-bordered tr > td {
    border: 1px solid #ebeef5;
    padding: 10px;
    font-size: 12px;
}

.blue-btn {
    background-color: #3374e2;
    display: inline-block;
    color: white;
    padding: 5px 12px;
    margin-right: 10px;
}

.white-btn {
    background-color: white;
    display: inline-block;
    color: black;
    padding: 5px 12px;
    border: 1px solid #d9d9d9;
    margin-right: 10px;
}

.ant-modal-title {
    font-size: 20px !important;
}

.subtitle-tag {
    margin: 10px 0px;
    font-weight: bold;
}

.table-bordered tr > td:first-child {
    text-align: left !important;
    max-width: 400px;
}

.table-bordered tr > th {
    background-color: #f5f5f5;
    border: 1px solid #ebeef5;
}

.address-selected-item {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #797979;
    font-size: 10px;
    margin: 10px 5px;
    display: flex;
    align-items: center;
}

.overlay-search {
    position: sticky;
    border: 1px solid black;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    border: 0px;
}

.container-item {
    height: 166px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    border: 1px solid #797979;
    box-sizing: border-box;
    overflow-y: scroll;
    position: relative;
}

.trading-log-modal {
    &.ant-modal .ant-modal-header {
        border-bottom-color: rgba(242, 242, 242, 1);
        padding: 16px 18px;
    }
    .ant-modal-body {
        padding: 0px;
        max-height: 55vh;
        overflow-y: auto;
    }
    .tradingLog {
        margin: 10px;
        padding: 10px;
        background-color: rgba(242, 242, 242, 1);
        font-family: 'ArialMT', 'Arial', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
    }
    &.ant-modal .ant-modal-footer {
        border-top-color: rgba(242, 242, 242, 1);
    }
}

.page.account-management {
    .ant-select.ant-select-sm {
        .ant-select-arrow {
            right: 4px;
        }
    }

    .dropdown-btn {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        padding-right: 3px;


        &:not(.ant-dropdown-open) {
            .icondropdown {
                color: @lineColor;
            }
        }
    }
}

// 账户管理 下拉菜单
.management-dropdown-overlay {
    .ant-dropdown-menu-item:hover {
        background-color: @subColorHoverBg;
    }
}
.work-order-detail {
    .header {
        flex-direction: column;

        .info {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            height: 50px;
            padding: 10px 30px;
            margin-top: 20px;
            margin-bottom: 30px;
            background-color: #f4f4f4;
        }
    }

    .content {
        .comment-list {
            .ant-list-header {
                .work-title {
                    height: 50px;
                    line-height: 50px;
                    padding-left: 16px;
                    font-size: 16px;
                    font-weight: 700;
                    color: @black85;
                    margin-top: 10px;
                    margin-bottom: 25px;
                    background-color: #f4f4f4;
                }
            }

            .ant-comment {
                .ant-comment-avatar {
                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }
                }

                .ant-comment-content-author {
                    display: flex;
                    justify-content: space-between;
                }

                .ant-comment-content-detail {
                    .file-list-box {
                        margin-top: 24px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        .file-item {
                            width: 100px;
                            height: 100px;
                            margin-bottom: 10px;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }

                            &> :last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer {
        margin-top: 20px;

        .communicate-title {
            font-size: 16px;
            font-weight: 700;
            color: @black85;
            margin-bottom: 16px;
        }
    }
}
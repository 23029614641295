.order-create {
    font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
    font-weight: 400;
    font-style: normal;
    .top {
        max-width: 634px;
        width: 100%;
    }
    .balance,
    .limit {
        font-size: 14px;
        color: #aaaaaa;
        display: inline-block;
        vertical-align: top;
        div {
            font-size: 14px;
            color: #333333;
        }
        span {
            font-size: 24px;
        }
    }
    .limit {
        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
                color: #aaaaaa;
                span {
                    display: block;
                    color: #333333;
                    margin-top: 14px;
                }
            }
        }
        span {
            font-size: 14px;
        }
        a {
            padding: 2px 12px 2px 15px;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(217, 217, 217, 1);
            color: #333333;
        }
    }
    hr {
        margin: 30px 0;
        border: 0;
        border-top: 1px solid #f2f2f2;
        // width:780px;
    }
    .ant-form.ant-form-horizontal {
        max-width: 634px;
    }
    button.ant-btn-default {
        border-color: rgba(217, 217, 217, 1);
    }
    .ant-form-item-extra {
        color: #02a7f0;
    }
}

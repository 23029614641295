.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}
/**
    解决antd升级 4.24.5后样式错误的问题
*/
.ant-form-horizontal .ant-form-item-control{
    min-width: 100px !important;
}

//drawer 内上传时 文件名过长
.ant-drawer{
    .ant-upload-list.ant-upload-list-text{
        width: 300px;
    }
}

.dividing-line {
    height: 1px;
    margin: 10px 0;
    background-color: @lineColor;
}

.ant-form-horizontal .ant-form-item-control {
    min-width: 1px;
}

.ant-select-selection-overflow-item{
    max-width: 95% !important;
}

.ant-table {
    .ant-table-tbody {
        .operate-cell {

            a {
                margin-right: 10px !important;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    a:not(:last-child) {
        margin-right: 12px;
    }
}

.page {
    padding-top: 20px;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .left {
            display: flex;
        }

        .tools-btn {
            margin-left: 16px;

            &> :nth-child(1n) {
                margin-left: 10px;
            }

            &> :first-child {
                margin-left: 0;
            }
        }

    }
}

.pd0 {
    padding: 0 !important;
}

.pd-t0 {
    padding-top: 0 !important;
}

.pd-l0 {
    padding-left: 0 !important;
}

// margin
.mg-t0 {
    margin-top: 0 !important;
}

.mg-l16 {
    margin-left: 16px !important;
}

.mg-r16 {
    margin-right: 16px !important;
}

.mg-t16 {
    margin-top: 16px !important;
}

.mg-b16 {
    margin-bottom: 16px !important;
}

// color
.color-primary {
    color: @brandColorDeep  !important;
}

.color-success {
    color: @subColorGreen  !important;
}

.color-error {
    color: @subColorRed  !important;
}

// width % => -p
.width350 {
    width: 350px !important;
}

.width400 {
    width: 400px !important;
}

.width600 {
    width: 600px !important;
}

// height % => -p
.height36 {
    height: 36px !important;
}

.height36-p {
    height: 36% !important;
}

// 多选框超过最大高度滚动显示
.multiple-select-scroll {
    .ant-select-selector {
        overflow-y: auto;
        max-height: 115px;
    }
}

.multi-select-search {
    .ant-select-selector {
        overflow-y: auto;
        height: 36px;
    }
}

.inline-link-btn {
    height: 20px;
    padding: 0px 0px 0px 15px;
    line-height: 12px;
}

// text
.text-center {
    text-align: center;
}

// arrow
.top-arrow {
    display: inline-block;
    height: 100%;
    transform: rotate(180deg);
}

.left-arrow {
    display: inline-block;
    height: 100%;
    transform: rotate(90deg);
}

.right-arrow {
    display: inline-block;
    height: 100%;
    transform: rotate(270deg);
}

// display flex
.display-flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
}

// heading
.heading-1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
}

.heading-2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
}

.heading-3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
}

// 超出省略
.ellipsis {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}

// 自动换行
.auto-break {
    word-wrap: break-word;
    white-space: normal;
    word-break: break-word;
}

.disabled-content {
    color: @disabledColor;
}

.font-color-black85 {
    color: @black85  !important;
}

.font-color-black65 {
    color: @black65  !important;
}

.font-color-black45 {
    color: @black45  !important;
}

.font-color-black25 {
    color: @black25  !important;
}
.table-search-form {
    margin-bottom: 6px;
    .ant-form-item {
        margin-bottom: 10px;
    }
    .ant-input-group,
    .ant-input,
    .ant-input-number-input-wrap,
    .ant-input-affix-wrapper,
    .ant-select,
    .ant-select-selector,
    .ant-select-selection-item,
    .ant-picker,
    .ant-btn {
        height: 36px !important;
    }
    .ant-input-affix-wrapper {
        padding-top: 0;
        padding-bottom: 0;
        .ant-input {
            height: 34px !important;
        }
    }
    .ant-select,
    .ant-select-selection-search,
    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-picker {
        line-height: 34px !important;
        & > input {
            line-height: inherit;
        }
    }
}
.i18n-selector {
    .ant-select-selector {
        border: none !important;

        .ant-select-selection-item {
            padding-right: 22px;
            padding-left: 22px;
            // color: #bababa;

            &:before {
                font-family: 'iconfont' !important;
                content: '\e65e';
                pointer-events: none;
                position: absolute;
                top: 1px;
                left: -1px;
                width: 20px;
                font-style: normal;
                vertical-align: baseline;
                font-size: 20px;
                text-align: center;
                text-transform: none;
            }
        }
    }
}
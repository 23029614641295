.wireframe {
  .wireframe-field {
    .phone-input {

      .code-select {
        width: 100%;

        &:hover,
        &.ant-select-open {
          z-index: 1;
        }

        .ant-select-selector {
          border-radius: 2px 0 0 2px;
        }
      }

      .number-input {
        width: 100%;
        border-radius: 0 2px 2px 0;
        margin-left: -1px;
      }
    }

    .field-upload {
      display: flex;
      justify-content: flex-start;

      .upload-list,
      .upload-box {
        display: inline-block;
      }

      .upload-list {
        display: flex;
        justify-content: flex-start;
        height: 100%;

        .upload-list-item {
          width: 104px;
          height: 104px;
          margin-right: 8px;
          margin-bottom: 8px;
          text-align: center;
          vertical-align: top;
          background-color: #fafafa;
          // border: 1px solid #d9d9d9;
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-image {
            width: 100%;
          }

          .group-image-delete {
            position: absolute;
            top: 1px;
            right: 5px;
            color: @subColorRed;
          }
        }
      }
    }
  }
}
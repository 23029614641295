.Loading {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75px;

  // put the loading children center
  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: -10px;
  }
}

.ant-upload .ant-spin-nested-loading .ant-spin{
  width: 100%;
}
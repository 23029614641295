// iconfont
.iconfont {
    font-size: 20px !important;
    vertical-align: middle !important;

    &.info-icon {
        color: @brandColorDeep;
    }

    &.success-icon {
        color: @subColorGreen;
    }

    &.error-icon {
        color: @subColorRed;
    }

    &.warning-icon {
        color: @subColorYellow;
    }
}

// 分页器 选择分页大小箭头图标设置
// 如果箭头图标改变，需手动修改 theme.less 文件，.ant-pagination-options-size-changer-insert.ant-select-arrow::after content的值
.ant-pagination-options-size-changer-insert.ant-select-arrow {
    font-family: "iconfont" !important;
    font-size: 20px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle !important;
}
.transaction-details-modal {
    &.ant-modal .ant-modal-header {
        border-bottom-color: rgba(242, 242, 242, 1);
        padding: 16px 18px;
    }
    .ant-modal-body {
        padding: 0px;
    }
    .details-display {
        margin: 10px;
        padding: 10px;
        background-color: rgba(242, 242, 242, 1);
        font-family: 'ArialMT', 'Arial', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
    }
    .ant-form-item-label {
        & > label {
            .font-color-black45;
        }
    }
    &.ant-modal .ant-modal-footer {
        border-top-color: rgba(242, 242, 242, 1);
    }
}

.order-detail {
    .order-status-stpes {
        border: 1px solid @lineColor;
        border-radius: 2px;
        padding: 24px;
    }

    .tools-btn {
        & .ant-btn {
            margin-right: 16px;
        }

        & .ant-btn:last-child {
            margin-right: 0px;
        }
    }

    .info-card {
        .ant-form-item-label {
            & > label {
                .font-color-black45;
            }
        }
    }
}

.substatuses-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.substatuses-table th,
.substatuses-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.substatuses-table th {
    background-color: #f2f2f2;
}

.substatuses-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.substatuses-table tr:hover {
    background-color: #e6f7ff;
}

.custom-radio-group .ant-radio-wrapper {
    margin-right: 70px;
    padding-left: 10px;
}

.batch-upload-btn {
    padding: 18px 5px 0px 0px;
}

.batch-upload-btn-display {
    display: flex;
    justify-content: space-between;
}

.batch-upload-status-text {
    color: red;
    padding: 0;
    cursor: pointer;
    pointer-events: none;
}

.batch-upload-status-link {
    color: red;
    padding: 0;
}

.required-field::before {
    content: '*';
    color: #ff4d4f;
    margin-right: 4px;
}

.savetrx-card-row {
    display: 'flex';
    justify-content: space-between;
    margin-bottom: 8px;
    color: white;
}

.savetrx-card-header {
    display: 'flex';
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 20px;
    color: #ffffff7f;
    font-weight: bold;
}

.custom-table-header .ant-table {
    line-height: 0px;
}

.custom-table-header .ant-table-tbody {
    text-align: center;
}

.custom-table-header .ant-table .ant-table-container .ant-table-header .ant-table-thead {
    line-height: 2px !important;
}

.channel-basis {
    .webhook-status {
        display: flex;
        flex-direction: column; 

        .add-webhook-btn {
            border: none;
            width: 780px;
            text-align: end;
            margin-top: -20px;
        }
    }
    .webhook-url-table {
        margin-bottom: 8px;

        .webhook-url { 
            width: 350px; 
            height: 36px;
            margin-right: 10px;
        }

        .item-brand { 
            width: 200px; 
            height: 36px;
            margin-right: 10px;
        }

        .item-name { 
            width: 200px; 
            height: 36px;
        }
    }
}
.main-container {
    width: 100%;
    height: 100%;
    min-width: 1200px;

    .head-container {
        width: 100%;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
        padding: 0 20px;
        padding-left: 5px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 999;

        .left-part {
            height: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .logo {
                width: 200px;
                // height: 100%;
                padding: 10px 20px;
            }

            .shop-list {
                max-width: 200px;
                margin: 0 20px;

                .ant-select-selection-item {
                    padding-right: 15px;
                }

                .shop-item {
                    max-width: 185px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .shop-home {
                        margin-right: 5px;
                    }

                    .shop-logo {
                        display: none;
                    }

                    .shop-name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .anticon {
                        font-size: 16px;
                        color: @lineColor;
                    }

                    .shop-arrow {
                        display: none;
                    }
                }
            }

            .main-menu {
                height: 100%;
                border-bottom: none;
                margin-top: -5px;
                min-width: 385px;
            }
        }

        .right-part {
            display: flex;
            align-items: center;

            .i18n-selector {
                .ant-select-selector {
                    border: 1px solid @lineColor !important;
                }
            }

            .logout {
                display: inherit;
                margin-left: 16px;

                .anticon {
                    font-size: 20px;
                    color: @lineColor;
                }
            }
        }
    }

    .side-container {
        border-right: 1px solid @lineColor;

        .sub-menu {
            height: 100%;
            border-right: none;
            // margin-top: 13px;

            .ant-menu-inline {
                .ant-menu-item::after {
                    border-right-width: 0;
                }
            }

            .ant-menu-submenu {
                & > .ant-menu-submenu-title {
                    & > .ant-menu-title-content {
                        margin: 0;
                    }
                }
            }
        }
    }

    .content-container {
        // overflow-y: auto;

        > .ant-layout {
            // width: 100%;
            // min-width: 1200px;
            // min-height: 100%;
            padding-bottom: 0;
            // padding: 0 24px 24px;

            .ant-breadcrumb {
                margin: 16px 0;
            }

            .main-content {
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                margin: 0;
                // padding: 0 20px 20px;
                position: relative;

                // 加载图标居中显示
                & > .Loading {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .page-header {
                    padding-left: 20px;
                    padding-right: 20px;
                    .ant-page-header {
                        padding: 15px 0;

                        .ant-page-header-heading-left,
                        .ant-page-header-heading-extra {
                            display: flex;
                            align-items: center;
                        }

                        .ant-page-header-heading-left {
                            .ant-page-header-back {
                                margin-right: 8px;
                            }

                            .ant-page-header-heading-title {
                                .heading-1;
                                color: @black85;
                            }

                            .ant-page-header-heading-sub-title {
                                .heading-2;
                                color: @black65;
                            }
                        }

                        .ant-page-header-heading-extra {
                            & > * {
                                margin-left: 16px;
                            }
                        }
                    }

                    & > a {
                        color: @black85;
                    }
                }

                .page-header-divider {
                    // width: calc(100% + 40px);
                    position: relative;
                    // left: -20px;
                    margin: 0px;
                    border-top-color: @lineColor;
                }
                .page-content {
                    overflow-y: auto;
                    height: calc(100vh - 129px);
                    padding: 0 20px 20px;
                }
            }
        }
    }
}

.create-merchant .ant-form-item {
    // 解决注册时，上传多图片高度溢出无法滚动的问题
    .ant-form-item-control-input-content {
        max-height: 220px;
        overflow-y: auto;
    }
}

@import './Fields/style.less';

// 线框风格
.wireframe {

  &>.ant-row {
    &>.ant-col {
      padding-left: 8px;
      padding-right: 8px;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }

      &:only-child {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }

  // label value 分行
  .ant-row.wireframe-field {
    display: block;
  }

  // 不需要换行
  .ant-input-group {
    .ant-row {
      display: flex;
    }
  }

  .ant-form-item,
  .custom-field {
    // margin-bottom: 16px;

    // 自定义表单label 样式
    .ant-form-item-label {
      width: 100%;
      text-align: left;

      &>label {
        // display: block;
        width: 100%;

        &>span:first-child {
          width: auto;
        }
      }

      .form-item-required {
        &:before {
          display: inline-block;
          margin-right: 4px;
          content: "*";
          line-height: 1;
          font-size: 14px;
          color: @subColorRed;
        }

        &.static-view:before {
          display: none;
        }
      }
    }

    &>.ant-form-item-control {
      .ant-form-item-control-input-content {

        &>.ant-input-group,
        &>.ant-input-group-compact,
        &>.ant-input,
        &>.ant-select,
        &>.ant-radio-group {
          display: block;
          max-width: 600px;
        }

        &>.ant-input-affix-wrapper {
          max-width: 600px;
        }

        .ant-table-wrapper {
          max-width: 800px;
        }

        &>.field-upload {
          .upload-list {
            // 多图片换行
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .inline-button-group {
    padding-top: 24px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-btn {
        &:not(:last-child) {
          margin-right: 16px;
        }

        &> :last-child {
          margin-right: 0;
        }
      }
    }
  }

  .block-button-group {
    .ant-form-item-control-input-content {
      .ant-btn {
        margin-top: 16px;
      }
    }
  }
}
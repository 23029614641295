.manage-image-modal {
    width: 850px;
    height: 650px;

    .ant-modal-body {
        padding-top: 0;

        .search-container {
            widows: 225px;
        }

        .group-image-list {
            max-height: 550px;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
        }

        .group-image-container {
            width: 120px;
            height: 120px;
            margin-right: 8px;
            margin-bottom: 8px;
            // border: 1px solid #d9d9d9;
            position: relative;

            .group-image-item {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .ant-image {
                    width: 100%;
                }
            }

            .tool-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 26px;
                display: flex;
                justify-content: space-between;
                padding: 5px;

                .group-image-check {
                    width: max-content;

                    .ant-checkbox {
                        top: -0.3em;
                    }
                }

                .group-image-delete {
                    position: absolute;
                    top: 1px;
                    right: 5px;
                    color: @subColorRed;
                }
            }
        }

        .upload-tab {
            .uploader {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                &.web-image-uploader,
                &.local-image-uploader {
                    .uploader-label {
                        margin-right: 16px;
                    }

                    .uploader-container {
                        flex-grow: 1;

                        .upload-url {
                            width: 400px;
                            display: flex;
                            align-items: baseline;
                            margin-bottom: 16px;

                            .ant-input {
                                height: @height-lg;
                                line-height: @height-lg;
                            }

                            .upload-button {
                                margin-left: 16px;
                            }
                        }
                    }

                    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
                        width: 120px;
                        height: 120px;
                    }

                    .ant-upload-picture-card-wrapper {
                        width: auto !important;
                    }
                }
            }

            .line {
                margin: 16px 0;
                border-bottom: 1px solid @lineColor;
            }
        }
    }
}
@import './orderCreate.less';
@import './orderDetails.less';

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.page.receiver-account {
    .page-header,
    .ant-divider {
        display: none;
    }
}

.alert-box-blue-box {
    padding: 16px 12px;
    border: 1px solid #7bc2fc;
    background-color: #e3f4fe;
    margin-bottom: 15px;
}

.currency-tips {
    color: #ffc53d;
    font-size: 13px;
    .anticon-info-circle {
        margin-right: 4px;
    }
}
.round-form-item {
    width: 305px;
    .ant-form-item-label {
        width: auto !important;
        text-align: right !important;
        span {
            margin-right: 3px;
            &::before {
                display: inline-block;
                margin-right: 4px;
                content: '*';
                line-height: 1;
                font-size: 14px;
                color: #d83e42;
            }
        }
    }
}

.tabbar-extra-flex {
    display: flex;
    align-items: center;
    gap: 10px;
}

.text-grey {
    color: #bfbfbf;
}

.order-status-card-buttons {
    display: flex;
    gap: 12px;
}

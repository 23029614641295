.all-message {
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .left {
            display: flex;

            .tools-btn {
                margin-left: 16px;

                &> :nth-child(1n) {
                    margin-left: 10px;
                }

                &> :first-child {
                    margin-left: 0;
                }
            }
        }

    }
}

.ant-drawer {
    .ant-drawer-header {
        .ant-drawer-title {
            .drawer-mian-title {
                .heading-3;
            }

            .drawer-sub-title {
                color: @black65;
            }
        }
    }

    .ant-drawer-footer {
        text-align: right;
    }
}
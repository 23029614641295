.preview-container {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;

    .default-theme,
    .full-theme {
        width: 100%;
        height: 100%;


        .preview-content {
            width: 100%;
            height: 100%;
            border: 1px solid @lineColor;
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-image {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
            }

            .image-preview {
                width: 100%;
            }

            .file-preview {
                font-size: 35px;
                color: @black85;
            }
        }

        .preview-operation {
            .view-btn {
                margin-right: 15%;
            }
        }
    }

    .default-theme {
        position: relative;
        width: 100%;
        height: 100%;

        .preview-operation {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            justify-content: center;
            align-items: center;
            background-color: @black65;
            color: #ffffff;

            .anticon {
                font-size: 14px;
            }
        }

        &:hover {
            .preview-operation {
                display: flex;
            }
        }
    }

    .full-theme {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .preview-content {
            max-width: 100px;
            max-height: 100px;
            margin-right: 25px;
        }

        .preview-operation {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .file-name,
            .tools-btn {
                display: inline-block;
                white-space: nowrap;
            }

            .file-name {
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 20px;
            }

            .tools-btn {
                &>.ant-btn:nth-child(1n) {
                    margin-right: 16px;
                }

                &>.ant-btn:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
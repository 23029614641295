// color
@primary-color: @brandColorDeep; // 全局主色
@link-color: @brandColorDeep; // 链接色
@success-color: @subColorGreen; // 成功色
@error-color: @subColorRed; // 错误色
@heading-color: @black85; // 标题色
@text-color: @black85; // 主文本色
@text-color-secondary: @black65; // 次文本色
@disabled-color: @disabledColor; // 失效色
@disabled-bg: rgba(0, 0, 0, 0.04);
@black: @black85;
@normal-color: @disabledColor;
@highlight-color: @subColorRed;
@processing-color: @primary-color;

// height rules
@height-base: 30px;
@height-lg: 36px;
@height-sm: 20px;

// font
@font-size-base: 14px; // 主字号
@font-size-lg: @font-size-base;
@font-size-sm: @font-size-base;

// common
// @line-height-base: 36px;

// select
@select-item-selected-color: @brandColorDeep;
@select-item-selected-font-weight: normal;
@select-item-selected-bg: @subColorHoverBg;
@select-item-active-bg: @select-item-selected-bg;

.ant-select {
    &.ant-select-show-arrow {

        // .ant-select-selection-item,
        // .ant-select-selection-placeholder {
        //     padding-right: 22px;
        // }

        .ant-select-arrow {
            width: 20px;
            height: 20px;
            top: 43%;
        }
    }

    // .ant-select-arrow .iconfont:not(.ant-select-suffix) {
    //     // 解决：使用 iconfont 时，tooltip 无效
    //     pointer-events: auto;
    // }

    &.ant-select-show-search {
        &:not(.ant-select-customize-input) .ant-select-selector input {
            height: 100%;
        }

        .ant-select-clear {
            right: 13px;
        }
    }
}

.ant-select-dropdown {
    // 导轨滑块颜色
    .rc-virtual-list-scrollbar {
        .rc-virtual-list-scrollbar-thumb {
            background-color: @black25  !important;
        }
    }

    .ant-select-tree-list-scrollbar {
        .ant-select-tree-list-scrollbar-thumb {
            background-color: @black25  !important;
        }
    }
}

// Table
@table-header-bg: @subColorHoverBg;
@table-header-color: @black85;
@table-header-font-size: 16px;
@table-font-size: 12px;
@table-row-hover-bg: @subColorHoverBg;

.ant-table {
    .ant-table-container {
        .ant-table-thead {
            line-height: 20px;

            &>tr {
                &>th {
                    border-bottom: none;

                    .anticon {
                        font-size: @table-font-size;
                    }

                    // 解决 table 开启选择功能后，未全选时 checkbox-inner显示位置异常
                    .ant-checkbox:not(.ant-checkbox-checked) {
                        .ant-checkbox-inner::after {
                            top: 50%;
                            left: 50%;
                        }
                    }
                }
                &>th.ant-table-column-sort{
                    background-color: rgba(6, 118, 234, 0.15) !important;
                }
            }

            .ant-table-cell {
                font-weight: bold;
            }
        }
    }

    .ant-table-tbody {
        &>tr {
            &.ant-table-expanded-row {
                & > td {
                    background-color: @subColorHoverBg;
                }
            }
            &>td {
                border-color: @lineColor;

                &.ant-table-cell:empty::before {
                    content: '-';
                }
            }
        }
        .ant-tag {
            font-size: 10px;
            line-height: 16px;
            padding: 0 4px;
            margin-left: 8px;
        }
    }
}

// picker
@picker-text-height: 32px;

// Buttons
@btn-shadow: none;
@btn-primary-shadow: none;
@btn-text-shadow: none;
@btn-primary-color: @white100;
@btn-primary-bg: @primary-color;
@btn-danger-bg: @error-color;
@btn-danger-border: @error-color;
@btn-disable-color: @disabled-color;
@btn-disable-bg: @white100;
@btn-disable-border: @disabled-color;
@btn-font-size-lg: @font-size-base;
@btn-font-size-sm: 12px;
@btn-height-base: @height-base;
@btn-height-lg: @height-lg;
@btn-height-sm: @height-sm;
@btn-default-color: @black65;
@btn-default-border: @black65;
@btn-text-hover-bg: transparent;

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active,
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: @white100;
    background: @disabled-color;
    border-color: @disabled-color;
}

// Modal
.ant-modal {
    .ant-modal-header {
        border-bottom-color: @lineColor;

        .ant-modal-title {
            .heading-3;
        }
    }

    .ant-modal-content {
        .ant-modal-body {
            .ant-modal-confirm-body {
                .ant-modal-confirm-title {
                    .heading-3;
                }

                &>.iconfont {
                    float: left;
                    margin-right: 6px;
                    height: 20px;
                    width: 20px;
                    position: relative;

                    &::before {
                        position: absolute;
                        top: 55%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &>.iconfont+.ant-modal-confirm-title+.ant-modal-confirm-content {
                    margin-left: 26px;
                }
            }

            .ant-modal-confirm-btns {
                .ant-btn {
                    height: 36px;
                }

                button+button {
                    margin-left: 16px;
                }
            }
        }
    }

    .ant-modal-footer {
        padding: 20px 24px;
        border-top-color: @lineColor;

        .ant-btn {
            height: 36px;
        }

        button+button {
            margin-left: 16px;
        }
    }
}

// Menus
.ant-menu {
    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: @subColorHoverBg;
    }

    .ant-menu-submenu {

        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
            background-color: @subColorHoverBg;
        }

        &.ant-menu-submenu-selected {
            color: @black85;
            background-color: rgba(0, 0, 0, 0.03);
        }

        &>.ant-menu-submenu-title {
            margin: 0;
        }
    }

    &.ant-menu-sub {
        &.ant-menu-inline {
            background-color: @white100;
        }

        .ant-menu-item {
            margin: 0;
        }
    }

    .ant-menu-item .ant-menu-item-icon,
    .ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon {
        margin-right: 8px;
    }
}

// Steps
.ant-steps {

    .ant-steps-item {

        &.ant-steps-item-wait,
        &.ant-steps-item-process {
            .ant-steps-item-title::after {
                background-color: @lineColor;
            }
        }

        .ant-steps-item-container {
            display: inline-flex;
            align-items: baseline;

            .ant-steps-item-content {
                .ant-steps-item-title {
                    font-size: 16px;
                    font-weight: bold;

                    &::after {
                        height: 2px;
                    }
                }
            }

            .ant-steps-item-icon {
                .step-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: #f3f3f3;

                    &:after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: @black25;
                        position: relative;
                    }

                    &.finished-step {
                        background-color: #d2e6fb;

                        &::after {
                            background-color: @brandColorDeep;
                        }
                    }

                    &.process-step {
                        background-color: #fff4d1;

                        &::after {
                            background-color: @subColorYellow;
                        }
                    }
                }
            }
        }
    }
}

// Tabs
.ant-tabs {

    &.ant-tabs-top,
    &.ant-tabs-bottom {

        &>.ant-tabs-nav::before,
        &>div>.ant-tabs-nav::before {
            border-bottom-color: @lineColor;
        }
    }

    &.ant-tabs-left {
        .ant-tabs-content-holder {
            border-left-color: @lineColor;
        }
    }

    &.ant-tabs-right {

        &>.ant-tabs-content-holder,
        &>div>.ant-tabs-content-holder {
            border-right-color: @lineColor;
        }
    }
}

// Drawer
.ant-drawer {
    .ant-drawer-header {
        border-bottom-color: @lineColor;
    }

    .ant-drawer-footer {
        padding-left: 24px;
        padding-right: 24px;

        &>button+button {
            margin-left: 16px;
        }
    }
}

// List
.ant-list {
    &.ant-list-split .ant-list-header {
        border-bottom-color: @lineColor;
    }

    .ant-list-items {
        &>li {
            border-bottom: 1px solid @lineColor;
        }
    }
}

// Pagination
.ant-pagination {
    &.ant-table-pagination {

        // 显示数据总量 左对齐
        .ant-pagination-total-text {
            flex-grow: 1;
        }

        .ant-pagination-item,
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            border: none;
        }

        .ant-pagination-item {
            min-width: auto;
            margin-right: 6px;

            a {
                width: min-content;
            }
        }

        .ant-pagination-prev {
            margin-right: 0;
        }

        .ant-pagination-item-active {
            &>a::after {
                content: '';
                display: block;
                border-bottom: 2px solid @primary-color;
            }
        }

        .ant-pagination-options {
            .ant-select {
                &.ant-pagination-options-size-changer .ant-select-selector {
                    border-radius: 0;
                    border-width: 0 !important;
                    border-bottom-width: 1px !important;
                }

                &:not(.ant-select-disabled):hover .ant-select-selector {
                    border-right-width: 0 !important;
                }

                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    padding-right: 22px;
                }
            }

            .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                box-shadow: none;
            }

            // 替换分页选择器的箭头图标
            .ant-pagination-options-size-changer-insert.ant-select-arrow {
                width: 20px;
                height: 20px;
                top: 43%;

                &::after {
                    content: '\e652';
                }

                .anticon {
                    display: none;
                }
            }

        }

        .ant-pagination-options-quick-jumper {
            input {
                border-radius: 0;
                border-width: 0 !important;
                border-bottom-width: 1px !important;

                &:hover {
                    border-right-width: 0 !important;
                }
            }

            input:focus,
            input-focused {
                box-shadow: none;
            }
        }
    }
}

// 分页器 设置最后一页的 margin-right为 0
// showSizeChanger、showQuickJumper 均为 false 时，li:nth-last-of-type(2)
ul.ant-pagination.ant-table-pagination li:nth-last-of-type(3) {
    margin-right: 0;
}

// Checkbox
.ant-checkbox {
    &.ant-checkbox-disabled {
        .ant-checkbox-inner {
            background-color: #f5f5f5;

            &::after {
                border-color: #d9d9d9;
            }
        }

        &+span {
            color: @black25;
        }
    }

    .ant-checkbox-inner::after {
        top: 45%;
        left: 20%;
    }
}

// Radio
.ant-radio {
    &.ant-radio-disabled {
        .ant-radio-inner {
            background-color: #f5f5f5;

            &::after {
                background-color: #d9d9d9;
            }
        }

        &+span {
            color: @black25;
        }
    }
}

// Form
.ant-form {
    .ant-form-item-has-error {

        .ant-input,
        .ant-input:hover,
        .ant-input:focus,
        .ant-input-affix-wrapper,
        .ant-input-affix-wrapper:hover .ant-input-affix-wrapper:focus,
        .ant-input-focused,
        .ant-input-affix-wrapper-focused {
            border-color: @subColorRed;
        }
    }
}

.ant-card {
    &.ant-card-bordered {
        border-color: @lineColor;

        .ant-card-head {
            border-color: @lineColor;
        }
    }
}

// Timeline
.ant-timeline {
    .ant-timeline-item-head-red {
        color: @subColorRed;
        border-color: @subColorRed;
    }

    .ant-timeline-item-head-green {
        color: @subColorGreen;
        border-color: @subColorGreen;
    }

    .ant-timeline-item-head-blue {
        color: @brandColorDeep;
        border-color: @brandColorDeep;
    }

    .ant-timeline-item-head-gray {
        color: @disabledColor;
        border-color: @disabledColor;
    }

    .ant-timeline-item-last {
        padding-bottom: 0;
    }
}

// 覆盖多选框，输入搜索内容时，margin-left：6.5px
.ant-select-multiple {
    .ant-select-selection-search {
        input.ant-select-selection-search-input {
            margin-left: 0 !important;
        }
    }
}
.filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-grow: 1;

  .filter-bar-left,
  .filter-bar-right {
    // &>div {
    //   height: 32px !important;
    // }

    .filter-item {
      height: 36px;
      margin-right: 16px;

      .ant-input-group,
      .ant-input,
      .ant-select-selector {
        height: 36px;
      }
      .ant-input-number-input-wrap{
        line-height: 36px;
        height: 36px;
      }
    }

    .filter-item:last-child {
      margin-right: 0;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 34px;
    }
  }

  .filter-bar-left {
    margin: 4px 0;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &> :last-child {
      margin-right: 0;
    }

    &>* {
      // font-size: 12px;
      vertical-align: text-bottom;
    }
  }

  .filter-bar-right {
    margin: 4px 0;

    &> :last-child {
      margin-right: 0;
    }

    &>* {
      vertical-align: text-bottom;
    }
  }
}
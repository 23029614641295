.channel-edit {
    .choose-bank-list {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .ant-checkbox {
            display: none;

            &+span {
                display: inline-block;
                width: 130px;
                height: 40px;
                border: 2px solid @lineColor;
                margin-right: 16px;
                margin-bottom: 10px;

                .bank-logo {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }

            &.ant-checkbox-checked+span {
                border-color: @subColorGreen;
            }
        }
    }

    .bank-card-list {
        .ant-table {
            width: 750px;
        }
    }

    .operate-btn {
        .delete-btn {
            color: @subColorRed;
            margin-left: 10px;
        }
    }
}
.login {
    width: 100%;
    height: 100%;

    .login-wrapper {
        height: 100%;
        width: 100%;

        .login-background {
            position: fixed;
            width: auto;
            height: auto;
            min-width: 1600px;
            max-width: 100%;
            top: 0;
            left: 0;
        }

        .login-panel {
            width: 480px;
            height: 100%;
            background: #FFFFFF;
            position: relative;
            padding: 15px 40px;
            overflow-y: auto;

            .i18n-selector {
                position: absolute;
                right: 40px;
                top: 15px;
                color: @black65;

                .ant-select-selector {
                    padding: 0;

                    .ant-select-selection-item {
                        padding: 0 18px 0 22px;

                        &:before {
                            top: 0px;
                            left: 2px;
                            font-size: 18px !important;
                            width: 18px;
                        }
                    }

                }

                &.ant-select-show-arrow .ant-select-arrow {
                    width: 12px;
                    height: 12px;
                    top: 45%;
                    right: 3px;

                    .iconfont {
                        font-size: 12px !important;
                    }
                }

                &.ant-select-show-arrow:not(.ant-select-open) .ant-select-arrow {
                    color: @black65;
                }
            }

            .login-panel-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 1;
                min-height: 100%;

                .login-logo {
                    display: block;
                    width: 174px;
                    height: 41px;
                    margin: 0 auto;
                    // padding-top: 43px;
                    margin-bottom: 87px;
                }

                .ant-form-item {
                    &:not(.block-button-group) {
                        margin-bottom: 24px;
                    }

                    // 解决注册时，上传多图片高度溢出无法滚动的问题
                    .ant-form-item-control-input-content {
                        max-height: 220px;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}